@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap');


.animate__delay{
  animation-delay: 0.5s;
}
.app-splash-screen {
  background-color: #000000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 100;
    transition:  0.5s;
  }
  .app-splash-inner .LogoDesign{
    box-shadow: rgba(247, 247, 247, 0) 2px 2px 2px 20px ;
  }
  .logoText{
    z-index: -2;
    position: relative;
    margin: 5px 0;
    border: 0;
    line-height: 90px;
    font-family: 'Titan One', cursive;

    /* opacity: 10; */
    
  }
  .logoText::after{
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000000;
    border: 0;
    /* border-left: 2px solid #FC6D6D; */
    /* font-weight: 500; */
    animation: typing 10s steps(10);
    animation-delay: 1s;
    animation-fill-mode:forwards;
    font-family: 'Titan One', cursive;

  }
  @keyframes typing {
    
   10%,100%{
      left: 100%;     
    }

  }
 
  .app-splash-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .app-logo{
    width: 50%;
    height: 50%;
    color: #fff;
  }
  
  .app-logo {
    /* background: url("./../../assets/lost-loch-transparent-logo.png"); */
    background-repeat: no-repeat;
    background-size: contain;
    /* animation: fadeInAnimation ease-in-out 1.1s infinite;*/
    animation-iteration-count: 1;
    animation-fill-mode: forwards; 
    animation: spin 2s 0s linear;
  }
  /* @keyframes fadeInAnimation {
    0% {
        transform: scale(5);
        opacity: 0.2;
        filter: blur(1.5rem);
    }
    50%{
     
    }
    100% {
        opacity: 1;
      
    }
} */


@keyframes spin {

  0% {
   
    transform: scale(5);
    opacity: 0.2;
    filter: blur(1.5rem);
  }
  30%{
    transform: rotate3d(0, 1, 0, 0);
  }

  100% {
    transform: rotate3d(0, 1, 0, 360deg);
  }

}
.appLoad{
    position: relative;
    top: -50px;
  }
  
/* splah spinner */
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/* new loader */
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #ffffff;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  top: -100px;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
