/* Landscape */
@media  (orientation: landscape) {
    .video{
        width: 100%;
        height: 80%;
    }
}
@media  (orientation: portrait) {
    .video{
        width: 100%;
        height:25%;
    }
}
@media all and (device-width: 667px) and (device-height: 375px) and (orientation:landscape) {
    .video{
        width: 100%;
        height: 70%;
    }
  }
 
  @media all and (device-width: 844px) and (device-height: 390px) and (orientation:landscape) {
    .video{
        width: 100%;
        height: 70%;
    }
  }
 
  .reactPlayer{
      border-radius: 20px;
  }