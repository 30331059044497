/* @import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap');
.bn632-hover {
  width: 260px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin-bottom: 20px;
  height: 40px;
  text-align:center;
  border: none;
  padding: 10px 30px;
  background-size: 300% 100%;
  border-radius: 50px;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.bn632-hover:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.bn632-hover:focus {
  outline: none;
}

.bn632-hover.bn26 {
  background-image: linear-gradient(
    to right,
    #25aae1,
    #4481eb,
    #04befe,
    #3f86ed
  );
  box-shadow: 0 2px 5px 0 rgba(65, 132, 234, 0.75);
}
.bgLogo {
    /* position: absolute; */
    /* margin-left: -180px; */
    width: 50px;
    height: 50px;
    z-index: 2px;
    border-radius: 50%;
    /* background: linear-gradient(180deg, #F7E653 0%, #75A422 100%); */
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.5);
  }
.logoFont{
  font-family:'Titan One', cursive ;
  font-size: 16px;
  color: white;
  letter-spacing: 0.1rem;
 
  margin-top: 15px;
}
.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 1.25cm 0cm 1.35cm 0.9cm;
  margin: 1cm 0cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.sub-page {
  height: 26.8cm;
}

@page {
  size: A4;
  margin: 0;
}
@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    -webkit-box-shadow: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}

