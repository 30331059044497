/** @format */
@media screen and (max-width: 600px) {
.water {
  width: 300px;
  height: 300px;
  /* margin-left: -50%; */
  background-color: skyblue;
  border-radius: 100% 0% 100% 100%;
  box-shadow: inset 0px 4px 50px #050606;
  position: relative;
  box-shadow: inset 0 0 30px 0 rgba(0, 0, 0, 0.5),
    0 4px 10px 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;
  transform: rotate(-20deg);
}
.water:before,
.water:after {

  content: "";
  position: absolute;
  width: 350px;
  height: 370px;
  top: -150px;
  background-color: #fff;
}
.water:before {
  border-radius: 45%;
  background: rgba(255, 255, 255, 0.7);
  animation: wave 5s linear infinite;
}
.water:after {
  z-index: 1;
  border-radius: 35%;
  background: rgba(255, 255, 255, 0.3);
  animation: wave 5s linear infinite;
}
@keyframes wave {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
div .image {
  transform: rotate(20deg);
  z-index: 5;
  filter: saturate(2);
  display: block;
}
}