.heading{
    font-weight: 600;
font-size: 20px;
/* line-height: 100%; */
    text-align: center;
    /* background: -webkit-linear-gradient(#027A0E, rgba(9, 8, 7, 0.96));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    color: #051607;
    padding-top: 10px; 
    /* opacity: 0.8; */
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
/* color: #2f5824; */
}
.listIcon{
    background: -webkit-linear-gradient(#027A0E, rgba(9, 8, 7, 0.96));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
@media (orientation: landscape){
    #recipeImg{
       max-height: 100%; 
     }
     #landscape{
        height: 95%;
        width:95%;
    }
}
#recipeImg{
    height: 100%;
   width:100%;
}
@media all and (device-width: 820px) and (device-height: 1180px) and (orientation:portrait) {
    #recipeImg{
        max-height: 100%; 
      }
      #landscape{
         height: 100%;
         width:95%;
     }
  }
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    #recipeImg{
        max-height: 100%; 
      }
      #landscape{
         height: 100%;
         width:95%;
     }
  }
  /* @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    #recipeImg{
        max-height: 100%; 
      }
      #landscape{
         height: 95%;
         width:95%;
     }
  } */