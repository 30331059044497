@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Paytone+One&display=swap');
.title_card{



width: 254px;
height: 42px;
margin-left: 65px;
background: #FFFFFF;
position: absolute;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.7);
border-radius: 0px 0px 10px 10px;
}
.title_card:hover{
box-shadow: 0px 4px 4px rgba(0, 0, 0, 1); 
align-self: center;  
}
.title{
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: bolder;
font-size: 23px;
line-height: 33px;
}
.title:hover{
color: #2f9906;
}
.transbox{
background-color: rgba(0, 0, 0, 0.5);
border-radius: 25px 25px 25px 25px;
filter: drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.4));
}
.intro_card{
/* background-image: url('../../../assets/img/fruitsvector.png'); */

position: relative;
width: 90%;
height: fit-content;
align-self: center;
margin-top: 20px;

background-size:cover;
background-repeat: no-repeat;
border-radius: 25px 25px 25px 25px;
box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
.intro_content{
color:white;
padding: 20px;
position: relative;
margin-top:20px ;
font-family: 'Nunito', sans-serif;
font-style: normal;
font-weight: 800;
font-size: 18px;
line-height: 28px;
display: flex;
align-items: center;
text-align: justify;
letter-spacing: 0.02em;
}
.HeadTxt{
    font-family: 'Paytone One', sans-serif;
   
}

.sustain_content{
    color:white;
    padding: 20px 20px 0px 20px;
    position: relative;
    /* margin-top:20px ; */
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: 0.02em;
    }

    .sustain_bullet{
        color:white;
        padding: 0px 20px 0px 20px;
        position: relative;
        /* margin-top:20px ; */
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        /* display: flex; */
        /* align-items: center; */
        /* text-align: justify; */
        /* letter-spacing: 0.02em; */
        }
        .changeStyle{
            margin-top: 0px;
        }