@import url('https://fonts.googleapis.com/css2?family=Outfit&family=Raleway:wght@200&family=Reggae+One&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&family=Poppins:wght@600&display=swap');



.loginsmallbutton{
   color: #FFFDFD;
   font-family: 'Poppins';
    background: rgba(32, 56, 148, 0.83);
}
.loginsmallbutton:hover
{
    top: -3px;
  box-shadow: -6px 14px 12px 2px rgba(90, 90, 90, .12);
}
.loginimage{
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
border-radius: 40px;

transition: width 2s;
}
.loginbutton{
   
    background: rgba(0, 76, 159, 0.83);
box-shadow: 0px 4px 4px rgba(97, 84, 84, 0.5);
border-radius: 15px;

}
.loginbutton:hover{
    /* left: 2px; */
    box-shadow: 2px 2px 2px 4px rgba(241, 241, 241, 0.568);  
}
.whitebox{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
border-radius: 0px 20px 20px 0px;
transition: width 2s;
}
.innertext{
    font-family: 'Reggae One';
    color: #031e52;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    font-weight: 400;
    padding: 12px;
    text-align: center;
}
.innerbox{
/* margin-top: 35%; */
/* position: absolute; */
z-index: 3;
background: rgba(220, 226, 234, 0.61);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 20px;
}
.textlogin{
    font-family: 'Reggae One';
    color: #FFFFFF;
    text-align: right;
    padding: 30px;
    padding-top: 24px; 
}
.glow-on-hover {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    /* background: #111; */
    background: rgba(0, 76, 159, 0.83);
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000
    /* color: rgba(0, 76, 159, 0.83); */
}
.glow-on-hover:active:after {
    background: rgba(0, 76, 159, 0.83);
}
.glow-on-hover:hover:before {
    opacity: 1;  
}
.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 76, 159, 0.83);
    left: 0;
    top: 0;
    border-radius: 10px;
}
@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    /* 100% { background-position: 0 0; } */
}
body{
  padding: 0;
  margin: 0;
}