
.linkedin{
    color: rgb(24, 7, 97);
    margin: 0px 7px ;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))
   
}
.instagram{
    color: rgb(180, 25, 90);
    margin: 0px 7px ;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))
   
}
.twitter{
    margin: 0px 7px ;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))

}
.facebook{
    margin: 0px 7px ;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))

}