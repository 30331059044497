.moreproduct{
    width: 160px;
    margin-left: 4%;
height:220px;
margin-top: 10%;
/* background: linear-gradient(180deg, rgba(255, 255, 255, 0.97) 10%, rgba(0, 0, 0, 0.9) 96.35%); */
background: rgba(147, 221, 221, 0.514);
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
border-radius: 10px;
background-size: 155px;
background-position-y: 20%;
background-position-x: -100%;
background-repeat: no-repeat;

}
.more_title{
width: 160px;
height: 50px;
/* margin-top: 42px; */
background: #031c35;
border-radius: 0px 0px 10px 10px;
}
.MainImg{
    transition: 1s;  
}
.MainImg:hover{
    transform: scale(1.2);
}