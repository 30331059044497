/*mobile view*/
.productCard{
  position: absolute;
  /* width: 380px; */
  /* height: 936px; */
  margin-left: 5px;
  margin-right: 5px;
  top: 410px;
  padding: 10px;
  background: linear-gradient(180deg, #A5A5A5 0%, rgba(196, 196, 196, 0) 100%);
  border-radius: 67px 67px 0px 0px;
  /* overflow-x: hidden; */
}

@media screen and (min-width: 900px){

  .productCard,.headText{
      display: none;
  }  

}
/* iphone small */


@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* .productCard{
    width: 92%;
    align-self: center;
    padding: auto;
    margin: auto auto;
    height: 100%;
  }   */
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .productCard{
    /* width: 85%;
    align-self: center;
    position: relative;
    top: 0;
    left: 0%;
    padding: auto;
    margin: auto auto;
    height: 100%; */
  }  
}
/* Desktop(portrait and landscape) ----------- */
@media only screen and (min-device-width: 1100px)  {
  .productCard{
    /* display: none; */
} 
}