@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&family=Poppins:wght@600&display=swap');
.aboutus{
font-family: 'Poppins', sans-serif;
font-style:normal;
font-weight: bolder;
font-size: 30px;
line-height: 42px;
position: relative;
display: flex;
align-items: center;
letter-spacing: 0.03em;
margin-left: 16px;
color: #073385;
/* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent; */


}
.line{
 position: relative;
 margin-top: -18px;
width: 225px;
height: 0px;
margin-left: 16px;
border: 2px solid rgba(0, 0, 0, 0.8);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}
.lines{
    position: relative;
    margin-top: -16px;
   width: 312px;
   height: 0px;
   margin-left: 16px;
   border: 2px solid #533010;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
   }
.about_card{
width: 311px;
height: fit-content;
margin-left: 26px;
margin-top: -60px;
background-size:cover;
background-repeat: no-repeat;
border-radius: 25px 25px 25px 25px;
box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.about_content{
color:white;
padding: 12px;
position: relative;
margin-top:100px ;
font-family: 'Nunito', sans-serif;
font-style: normal;
font-weight: 800;
font-size: 16px;
line-height: 28px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.07em;
}

.about{
    margin-bottom: 7px;
    width: 40px;
    height: 40px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
}